import { useState, useEffect, useContext } from 'react';
import { Container, Row, Badge } from 'react-bootstrap';
import hubStyles from './hub.module.css';
import PurchasedExperiences from './PurchasedExperiences';
import hubServices from '../../Services/hubServices';
import AuthContext from '../Context/AuthContext';



const PastWorkshops = () => {

    const { setIsLoading } = useContext(AuthContext);
    const [expiredWorkshops, setExpiredWorkshops] = useState([]);
    const [workshopsFetched, setWorkshopsFetched] = useState(false);
      
  useEffect(() => {
    if (!workshopsFetched) {
      async function fetchExpiredWorkshops() {
        try {
          setIsLoading(true);
          const response = await hubServices.fetchExpiredExperiences();
          //console.log("fetchPurchasedExperiences Response:", response);
          
          if (response !== null && response.statusCode === 200) {
            if(response.data !== null && response.data.length >0) {

              // Custom function to format date as dd/mm/yyyy
              const formatDate = (date) => {
                const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
                return date.toLocaleDateString('en-GB', options);
              };

            // Iterate over each purchase experience in response.data
            const finalResponse = response.data.map(experience => {
              const dobDate = experience.datePurchased ? new Date(experience.datePurchased) : null;

              if (dobDate) {
                  return {
                      ...experience,
                      datePurchased: formatDate(dobDate)
                  };
              } else {
                  return experience;
              }
            });
            console.log('experiencess', finalResponse);
            setExpiredWorkshops(finalResponse);
            setWorkshopsFetched(true);
            }
          }
        } catch (error) {
          console.error("fetchPastPurchasedWorkshops Error:", error);
          setWorkshopsFetched(false);
        }finally{
          setIsLoading(false);
        }
      }
      fetchExpiredWorkshops();
    }

  }, [workshopsFetched, setIsLoading]);


  return (
    <Container>
        <Row className={`d-flex flex-column align-items-right `}>

        <h5 className={`mt-3 ${hubStyles.ActionHeader}`} >Past Workshops <Badge pill className={hubStyles.workshopsCountBg}>{expiredWorkshops.length}</Badge></h5>

        <Row className="mt-2 RightPadding">
        
          <PurchasedExperiences modeType={'expiredWorkshops'} experiences={expiredWorkshops} />

        </Row>

        <br/>
        <br/>
        
        </Row>
    </Container>
      
  );
};

export default PastWorkshops;