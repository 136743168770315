import React, { useState, useEffect, useContext, useRef } from "react";
import ReactPlayer from 'react-player';
import { Row, Button, Toast } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import styles from "./hub.module.css";
import { useParams } from 'react-router-dom';
import axiosAPI from "../../Services/axiosAPI.js";
import BuyerCreateReview from "./BuyerCreateReview.js";
import userServices from "../../Services/userServices.js";
import { Carousel } from 'react-responsive-carousel';
import { FaAngleDown } from "react-icons/fa";
import MessagingServices from "../../Services/MessagingServices.js";
import { Link } from "react-router-dom";
import AuthContext from "../Context/AuthContext.js";


export default function ExperienceHubItemContent({ openProductChat }) {

  const { id } = useParams();
  const [experienceDetails, setExperienceDetails] = useState([]);
  const { setIsLoading } = useContext(AuthContext);

  const [showDescription, setShowDescription] = useState(true);
  const [showMaterial, setShowMaterial] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const [experienceId, setExperienceId] = useState(null);
  const [showReviewModal, setShowReviewModal] = useState(false);

  const imageTimeoutRef = useRef(null);
  
  useEffect(() => {
    const getExperienceDetails = async () => {
      try {
        setErrorMessage("");
        setIsLoading(true);
        const response = await axiosAPI.axiosAuth.get(`/experiences/items/${id}`);
        if (response.data !== null && response.data.statusCode === 200 && response.data.data !== null && response.data.data.length > 0) {
          setExperienceDetails(response.data.data);
        }
      } catch (error) {
        console.error("API Error:", error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    };
    getExperienceDetails();

    // Clear timeout when component unmounts
    return () => {
      if (imageTimeoutRef.current) {
        clearTimeout(imageTimeoutRef.current);
      }
    };
  }, [id, setIsLoading]);

  const toggleShowDescription = () => setShowDescription(!showDescription);
  const toggleShowMaterial = () => setShowMaterial(!showMaterial);

  const FollowHandler = async (userId, type) => {
    setErrorMessage("");
    if (!userId) {
      console.log("Please provide the User ID first.");
      return;
    }
    setIsLoading(true);

    try {
      if (type) {
        await userServices.followUser(userId);
      } else {
        await userServices.unfollowUser(userId, true);
      }

      let updatedExperienceDetails = [...experienceDetails];
      updatedExperienceDetails[0].isFollowing = !updatedExperienceDetails[0].isFollowing;
      setExperienceDetails(updatedExperienceDetails);
      
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenReviewModal = (id) => {
    setShowReviewModal(true);
    setExperienceId(id);
  };

  const handleCloseReviewModal = () => {
    setShowReviewModal(false);
  };

  const getRoomtoChat = async (sellerId) => {
    setErrorMessage("");
    try {
      setIsLoading(true);
      const response = await MessagingServices.fetchRoomIdtoChat(sellerId);
      if (response.statusCode === 200 && response.message?.channel_url) {
        setTimeout(() => {
          openProductChat(response.message.channel_url);
        }, 5000);
      } else {
        setErrorMessage(response.message || "Error while opening the chat");
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    }
  };

  const handleVideoEnd = (id) => {
    handleOpenReviewModal(id);
  };

  const handleImageLoad = (id) => {
    imageTimeoutRef.current = setTimeout(() => {
      handleOpenReviewModal(id);
    }, 5000); // 5 seconds delay
  };

  const renderArrowPrev = (onClickHandler, hasPrev, label) => (
    hasPrev && (
      <button type="button" onClick={onClickHandler} title={label} className={`${styles.arrow} ${styles.arrowLeft}`}>
        &#9664;
      </button>
    )
  );

  const renderArrowNext = (onClickHandler, hasNext, label) => (
    hasNext && (
      <button type="button" onClick={onClickHandler} title={label} className={`${styles.arrow} ${styles.arrowRight}`}>
        &#9654;
      </button>
    )
  );

  // const renderContent = (item, index) => {
  //   if (item.contentUrl.includes('.png') || item.contentUrl.includes('.jpg') || item.contentUrl.includes('.jpeg') || item.contentUrl.includes('.gif')) {
  //     return (
  //       <img
  //         src={item.contentUrl}
  //         alt={`Feed ${index}`}
  //         className={styles.Exp_reactplayer}
  //         onLoad={() => handleImageLoad(item.id)}
  //       />
  //     );
  //   } else {
  //     return (
  //       <div>
  //         <ReactPlayer
  //           className={styles.Exp_reactplayer}
  //           url={item.contentUrl}
  //           controls={true}
  //           onEnded={() => handleVideoEnd(item.id)}
  //           config={{
  //             file: {
  //               attributes: {
  //                 controlsList: 'nodownload', // Disable download option
  //               },
  //             },
  //           }}
  //         />
  //         {/* Inline style to hide carousel-status class */}
  //         <style>
  //           {`
  //             .carousel-status {
  //               display: none !important;
  //             }
  //           `}
  //         </style>
  //       </div>
  //     );
  //   }
  // };

  const [playingIndex, setPlayingIndex] = useState(null); // Track which video is playing

  const handlePlay = (index) => {
    setPlayingIndex(index); // Set the playing video index
  };

  const renderContent = (item, index) => {
    if (item.contentUrl.match(/\.(png|jpe?g|gif)$/)) {
      return (
        <img
          src={item.contentUrl}
          alt={`Feed ${index}`}
          className={styles.Exp_reactplayer}
          onLoad={() => handleImageLoad(item.id)}
        />
      );
    } else {
      return (
        <div>
          <ReactPlayer
            className={styles.Exp_reactplayer}
            url={item.contentUrl}
            controls
            playing={playingIndex === index} // Only play the current video
            onPlay={() => handlePlay(index)} // Set playing index when a video starts
            onEnded={() => handleVideoEnd(item.id)}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                },
              },
            }}
          />
          <style>
            {`
              .carousel-status {
                display: none !important;
              }
            `}
          </style>
        </div>
      );
    }
  };

  
  return (
    <div className={`${styles.ExpHubItemContainer}`}>
      <Row className={`boxBody`}>

      {errorMessage ? <div className={styles.errorMessage} >{errorMessage}</div> : null}
                
        {/* {isLoading ? (
            <div className={styles.boastLoadContent}>
              <Spinner animation="grow" size="sm" className='text-center' />
              <Spinner animation="grow" className='text-center' />
              <Spinner animation="grow" size="sm" className='text-center' />
              <Spinner animation="grow" className='text-center' />
              <Spinner animation="grow" size="sm" className='text-center' />
            </div>
        ) : ( */}
          <>
            {experienceDetails.length > 0 ? (
              experienceDetails.map((experience, index) => (
                <div key={index} className={`workshopContentDiv ${styles.workshopContentDiv}`}>
                  <div className={`workshopProfile ${styles.ExpHubItem_ProfileCont}`}>
                    <div className={styles.ExpHubItem_ProfileLeft}>
                      <div>
                        <Link to={`/seller/public-profile/${experience.sellerId}`}>
                          <img src={experience.sellerProfileImg ? experience.sellerProfileImg : "/Content/Images/empty_user.jpg"}
                            alt="workshop_user"
                            className={styles.ExpHubItem_ProfileImg}
                          />
                        </Link>
                      </div>
                      <div>
                        <div className={styles.workshop_sellername}>{experience.sellerName}</div>
                        {/* <div className={styles.workshop_sellerdesc}>{experience.sellerBio}</div> */}
                      </div>
                    </div>

                    <div className={styles.ExpHubItem_ProfileRight}>
                      {(!experience.contentOwner && experience.isFollowing) ? (
                        <Button className={`${styles.ExpHubItem_ProfileBtn}`} onClick={() => FollowHandler(experience.sellerId, false)}>
                          Following
                        </Button>
                      ) : ((!experience.contentOwner && !experience.isFollowing) ? (
                        <Button className={`${styles.ExpHubItem_ProfileBtn}`} onClick={() => FollowHandler(experience.sellerId, true)}>
                          Follow
                        </Button>
                      ) :
                        null
                      )}

                      {!experience.contentOwner && (
                        <div style={{display: 'none'}}>
                          <button className={`${styles.ExpHubItem_ProfileChatBtn}`} onClick={() => getRoomtoChat(experience.sellerId)}>
                            <img src="/Content/Images/icons/icons-message.png" alt="hub_chat" className={styles.ExpHubItem_ChatImg} />
                            &nbsp; Chat with Host
                          </button>
                        </div>
                      )}

                      <div>
                        <Link to="/invitations/new-invitation" state={{ workshopId: experience.sellerId, workshopCaption: experience.caption, partyType: 'one' }} >
                          <button className={`${styles.ExpHubItem_WatchPartyBtn}`} >
                            Create Watch Party
                          </button>
                        </Link>
                      </div>

                    </div>
                  </div>

                  <Row className={`workshopDescData ${styles.ExpHubItem_TitleFlex}`}>
                    <div className={styles.ExpHubItem_Title}>{experience.caption}</div>
                  </Row>

                  <div className={styles.ExpHubItem_VideoCont}>
                    {experience.content.length > 1 ? (
                      <Carousel
                        showThumbs={false}
                        showIndicators={false}
                        renderArrowPrev={renderArrowPrev}
                        renderArrowNext={renderArrowNext}
                        className={styles.customCarousel}
                      >
                        {experience.content.map((item, index) => (
                          <div key={index} className={styles.carouselItem}>
                            {renderContent(item, index)}
                          </div>
                        ))}
                      </Carousel>
                    ) : (
                      renderContent(experience.content[0], 0)
                    )}
                  </div>

                  <Row className={`text-end mt-2`}>
                    {/* <div className={styles.workshopReviewSection}>
                      <Button className={`${styles.addFeedbackBtn}`} onClick={() => handleOpenReviewModal(experience.id)}>
                        Add Feedback
                      </Button>
                    </div> */}
                    <BuyerCreateReview show={showReviewModal} handleClose={handleCloseReviewModal} experienceId={experienceId} />
                  </Row>

                  <div className={styles.ExpHubItem_DescCont}>
                    <div className={styles.toggleDropdowndiv}>
                      <div className={styles.ExpHubItem_DescTitle}>Description</div>
                      <Button onClick={toggleShowDescription} className={`${styles.toggleDropdownClick}`}>
                        <FaAngleDown />
                      </Button>
                    </div>
                    <Toast show={showDescription} onClose={toggleShowDescription} className={styles.toggleToastStyles}>
                      <div className={styles.ExpHubItem_DescText}>{experience.desc}</div>
                    </Toast>
                  </div>

                  <div className={styles.ExpHubItem_ExtraMaterialCont}>
                    <div className={styles.toggleDropdowndiv}>
                      <div className={styles.ExpHubItem_DescTitle}>Material required for Workshop</div>
                      <Button onClick={toggleShowMaterial} className={`${styles.toggleDropdownClick}`}>
                        <FaAngleDown />
                      </Button>
                    </div>
                    <Toast show={showMaterial} onClose={toggleShowMaterial} className={styles.toggleToastStyles}>
                      <div className={styles.ExpHubItem_DescText}>{experience.extraInfo}</div>
                    </Toast>
                  </div>
                </div>
              ))
            ) : (
              <div>
                <p className={styles.noProductdata}>No product details available or expired.</p>
              </div>
            )}
          </>
        {/* )} */}
      </Row>

      <br />
    </div>
  );
}
