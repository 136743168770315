import { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import ExperienceHubItemContent from './ExperienceHubItemContent';
import SendBird from '../SendBird';
import styles from './hub.module.css';


const ExperienceHubStreamPage = () => {

  const [typeOfChat, setTypeOfChat] = useState("");
  const [channelUrl, setChannelUrl] = useState(null);
  const [sendBirdKey, setSendBirdKey] = useState(0);

  const openMessagingchat = (roomId) => {
    if (roomId) {
      setTypeOfChat('one-to-one');
      setChannelUrl(roomId);
      setSendBirdKey(prevKey => prevKey + 1);  // Force re-render by changing key
    }
  };
  return (
      <main className="MainpartSetup HomeViewportHt">
        <Row className="mainPad">

          <Col md={9} xs={12} lg={9}>
            <ExperienceHubItemContent openProductChat={openMessagingchat} />
          </Col>
          
          <Col md={3} xs={0} lg={3} className={`${styles.responsiveDisplay}`}>
          <Container className="RightPadding stickySidebar">
              <SendBird
                key={sendBirdKey} // Unique key to force re-render
                openChat={typeOfChat} 
                openUrl={channelUrl} 
              />
            </Container>
          </Col>

        </Row>
      </main>

  );
}


export default ExperienceHubStreamPage;
