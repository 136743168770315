import { useState } from 'react';
import UpdateProfilePage from './Profile/UpdateProfilePage';
import Header from './Navbar/Header';
import { useParams } from 'react-router-dom';


const UpdateUserProfile = () => {
  
  const [loginStatus] = useState('loggedIn');
  let { profileStatus } = useParams();


  return (
    <div className="sellerBankSection">

      <Header loginStatus={loginStatus} />

      <main className="MainpartSetup viewportHt">
        <UpdateProfilePage profileStatus={profileStatus} />
      </main>

    </div>
  );
}


export default UpdateUserProfile;