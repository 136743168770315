import { useState } from 'react';
import { Row, Col, Container, Image, Button } from 'react-bootstrap';
import profileStyles from './Profile.module.css';
import { Link } from 'react-router-dom';
import loginApiServices from '../../Services/loginApiServices';
import LoadingOverlay from '../BaseComponents/LoadingOverlay';



const UploadProfile = () => {

  const [profilePicture, setProfilePicture] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');  

  const UploadProfile = () => {
    document.getElementById('uploadProfile').click();
  };

  const handleImageChange = (event) => {
    setProfilePicture(event.target.files[0]);
  };


  const uploadProfilePicture = async (e) => {
    setErrorMessage('');
    setSuccessMessage('');
    e.preventDefault();

    if (!profilePicture) {
      setErrorMessage("Invalid file format.");
      return;
      }

      const formData = new FormData();
      formData.append('profilePicture', profilePicture);
     
      const formDataObject = {};
      for (let [key, value] of formData.entries()) {
        formDataObject[key] = value;
      }

      try {
        setIsLoading(true);
        const response = await loginApiServices.userUploadProfileSingup(formDataObject);
        if (response.statusCode === 200) {
          if(response.data && response.data[0] !== null && response.data[0].uploadedPath !== null){
            const userProfileData = localStorage.getItem('userProfileData');
              if (userProfileData) {
                const parsedData = JSON.parse(userProfileData);
                parsedData.imageUrl = response.data[0].uploadedPath;
                console.log("Parsed::", parsedData);
                localStorage.setItem('userProfileData', JSON.stringify(parsedData));
              }
            setSuccessMessage("Profile Picture updated Successfully. Please refresh the page to view the Changes.");

          } else {
            setErrorMessage(response.message);
          }
        } else {
          setErrorMessage(response.message);
        }
      
      } catch (error) {
        console.error("API Error:", error);
        setErrorMessage("An error occurred while uploading the profile picture.");
      } finally {
        setIsLoading(false);
      }
  };
    

  return (
    
    <Container className='RightPadding'>
    <Row className={`d-flex flex-column align-items-center boxBody boxPadding`}>

        <h5 className={`mb-3 mt-2 mainHeaderline`}>Upload Profile Picture</h5>

        <Row className='profileBoxContent d-flex align-items-center justify-content-center'>
        
        {isLoading ? (
          <LoadingOverlay />
        ):(
        <>  
          <div className='text-center'>
            <label className={`text-danger`}>{errorMessage}</label>
            <label className={`text-sucess`}>{successMessage}</label>
          </div>

        {!profilePicture ? (
            <Row className={`mb-5 mt-5 ${profileStyles.UploadProfileRow}`} onClick={UploadProfile}>
                <div className={`d-flex align-items-center justify-content-center ${profileStyles.UploadProfileImagediv}`}>
                    <Image
                    src="/Content/Images/icons/icons_upload.png"
                    alt="Profile"
                    roundedCircle
                    className={profileStyles.UploadProfileImage}
                    />
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                  <label className={profileStyles.NormalText}>
                    <Link className={profileStyles.tagA}> Upload a file </Link>
                      or drag and drop
                  </label>
                  <input type="file"
                        id="uploadProfile"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                        accept="image/*"
                      />
                </div>

            </Row>

          ) : (
          <Row className={`mb-5 mt-5 ${profileStyles.UploadProfileRow}`} >
              <Image src={profilePicture ? URL.createObjectURL(profilePicture) : '/Content/Images/empty_user.jpg'}
                alt="Profile" className={profileStyles.UploadedImage} roundedCircle />
          </Row>
        )}

        </>
        )}
        
        <Row className='d-flex align-items-center justify-content-end'>
              <Col xs='auto'>
                <Button as={Link} className='CancelButtonSetup' to={`/seller/public-profile/${localStorage.getItem('sendbirdUserId')}`}>
                  Cancel
                </Button>    
              </Col>
                &nbsp;&nbsp;
              <Col xs='auto'>
                <Button className='SubmitButtonSetup' onClick={uploadProfilePicture} >Submit</Button>
              </Col>
        </Row>

        </Row>
        
    </Row>
    </Container>
  );
};

export default UploadProfile;
