import { useState, useEffect, useContext } from 'react';
import { Container, Row, Badge } from 'react-bootstrap';
import hubStyles from './hub.module.css';
import UpcomingInvitedWorkshops from './UpcomingInvitedWorkshops';
import hubServices from '../../Services/hubServices';
import AuthContext from '../Context/AuthContext';


const UpcomingWorkshops = () => {

    const { setIsLoading } = useContext(AuthContext);
    const [upcomingInvitedWorkshops, setUpcomingInvitedWorkshops] = useState([]);
    const [workshopsFetched, setWorkshopsFetched] = useState(false);
      
  useEffect(() => {
    if (!workshopsFetched) {
      async function fetchUpcomingInvites() {
        try {
          setIsLoading(true);
          const invitesResponse = await hubServices.fetchInvitations('invited');
          
          if (invitesResponse && invitesResponse.statusCode === 200) {
            const acceptedWorkshops = (invitesResponse.data || []).filter(
              (workshop) => workshop.acceptedInvitation === true
            );
            setUpcomingInvitedWorkshops(acceptedWorkshops);
          }
          
        } catch (error) {
          console.error("fetchUpcomingInvites Error:", error);
          setWorkshopsFetched(false);
        } finally {
          setIsLoading(false);
        }
      }
      fetchUpcomingInvites();
    }

  }, [workshopsFetched, setIsLoading]);


  return (
    <Container>
        <Row className={`d-flex flex-column align-items-right `}>

        <h5 className={`mt-3 ${hubStyles.ActionHeader}`} >Upcoming Workshops <Badge pill className={hubStyles.workshopsCountBg}>{upcomingInvitedWorkshops.length}</Badge></h5>

        <Row className="mt-2 RightPadding">
        
          <UpcomingInvitedWorkshops workshops={upcomingInvitedWorkshops} />

        </Row>

        <br/>
        <br/>
        
        </Row>
    </Container>
      
  );
};

export default UpcomingWorkshops;