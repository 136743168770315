import { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import profileStyles from './Profile.module.css';
import AboutProfile from "../BaseComponents/AboutProfile";
import UserProfile from "../BaseComponents/UserProfile";
import BankDetails from "./BankDetails";
import UploadProfile from './UploadProfile';
import BasicDetails from './BasicDetails';
import Bio from './Bio';
import ContactDetails from './ContactDetails';
import Interests from './Interests';
// import StripeDashboard from './Profile/StripeDashboard';


const UpdateProfilePage = ({ profileStatus }) => {
  
    // const navigate = useNavigate();
    // const [stripeUrl, setStripeUrl] = useState('');
    // const [loginStatus] = useState('loggedIn');
    // let { profileStatus } = useParams();
    const [isStripe, setIsStripe] = useState('');

    useEffect(() => {
      const profileData = localStorage.getItem('userProfileData');
      if (profileData) {
        const parsedData = JSON.parse(profileData);
        // console.log("User Data::", parsedData)
        if(parsedData.stripeAccountExists) {
          setIsStripe(true);
        }
      }
    }, []);


    // const onStripeDashboard = (url) => {
    //   setStripeUrl(url);
    //   navigate('/profile/stripe-dashboard');
    // };


  return (
    <Container fluid className="updateProfilePageSection">
      <main className="MainpartSetup viewportHt">

        <Row className={`mainPad`}>

          <Col md={3} className={`${profileStyles.responsiveDisplay}`} >
            <UserProfile />
            <br />
            <AboutProfile isStripe={isStripe} />
          </Col>

          <Col md={9}>
            {profileStatus === 'update_profile_picture' && <UploadProfile />}
            {profileStatus === 'update_basic_details' && <BasicDetails />}
            {profileStatus === 'update_bio' && <Bio />}
            {profileStatus === 'update_contact_details' && <ContactDetails />}
            {profileStatus === 'stripe-account-details' && <BankDetails isStripe={isStripe} />}
            {profileStatus === 'update_interests' && <Interests />}
            {/* {profileStatus === 'stripe-dashboard' && <StripeDashboard stripeUrl={stripeUrl} />} */}

          </Col>

        </Row>

      </main>
    </Container>
  );
}


export default UpdateProfilePage;