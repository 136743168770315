import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './Education.module.css';
import UserProfile from '../BaseComponents/UserProfile';
import SendBird from '../SendBird';
import SellerdbActions from './SellerdbActions';
import EducationPage from './EducationPage';


const EducationHubDetails = ({ type }) => {


  return (

      <main className="MainpartSetup">
        <Row className={`mainPad`}>
          <Col md={3} className={`${styles.responsiveDisplay}`}>
            <UserProfile />
            <br />
            <SellerdbActions type={type} />

          </Col>
          <Col md={6}>
            <EducationPage  />
          </Col>
          
          <Col md={3} className={`${styles.responsiveDisplay}`}>
            <SendBird />
          </Col>
        </Row>
      </main>

  );
}

export default EducationHubDetails;
