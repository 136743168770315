import { useState, useEffect } from 'react';
import { Row, Col, Button, Container, Form, Image, Modal, Toast } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import profileStyles from './Profile.module.css';
import LoadingOverlay from '../BaseComponents/LoadingOverlay';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import userServices from '../../Services/userServices';



const BasicDetails = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  // const [user, setUser] = useState({});

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  const [user, setUser] = useState({
    fullname: "",
    username: "",
    email: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
    dob: null,
  });

  const [errors, setErrors] = useState({
    fullname: "",
    username: "",
    email: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
    dob: "",
  });

  const handleInputs = (value, name, date, field) => {
    // Check if name is provided (for regular form inputs)
    if (name) {
        setUser({ ...user, [name]: value });
        setErrors({ ...errors, [name]: '' });
    } else {
        // Update the date field with the selected date
        setUser(prevUser => ({
            ...prevUser,
            [field]: date
        }));
    }
    setErrorMessage("");
};


  useEffect(() => {
    // Retrieve data from localStorage
    const profileData = localStorage.getItem('userProfileData');
    if (profileData) {
      // Parse the JSON data
      const parsedData = JSON.parse(profileData);
      // setUserProfileData(parsedData);
      // console.log("Data::", parsedData)
      const dobDate = parsedData.dob ? new Date(parsedData.dob) : null; // Parse dob string to Date object
            
      setUser({
        fullname: parsedData.name || '',
        username: parsedData.userId || '',
        email: parsedData.emailId || '',
        // password: parsedData.pwd || '123456789',
        dob: dobDate || '',
      });

      setCurrentUsername(parsedData.userId);

    }
    
  }, []);

  const updateBasicDetails = async (e) => {
    
    e.preventDefault();
    const { fullname, username, email, dob } = user;
    const newErrors = { fullname: "", username: "", email: "", dob: ""};
    
    if (!fullname) {
      newErrors.fullname = "Please enter full name first.";
      setErrors(newErrors);
      return;
    }
    if (!username) {
      newErrors.username = "Please enter username for the User.";
      setErrors(newErrors);
      return;
    }
    if (!email) {
      newErrors.email = "Please enter email ID";
      setErrors(newErrors);
      return;
    }
    if (!dob) {
      newErrors.dob = "Please select DOB";
      setErrors(newErrors);
      return;
    }
    
    if (newErrors.username || newErrors.email || newErrors.fullname || newErrors.dob) {
      setErrors(newErrors);
      return;
    }
    
    setErrorMessage("");
    setIsLoading(true);

    let formattedDate = "";
    if(dob !== "" && dob !== null){
        const year = dob.getFullYear();
        const month = String(dob.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(dob.getDate()).padStart(2, '0');
        // Create the formatted date string
        formattedDate = `${year}/${month}/${day}`;
    }

    try {

      const response = await userServices.updateUserProfileBasic(formattedDate);
      
      if (response.statusCode === 200) {
          const profileData = localStorage.getItem('userProfileData');
          if (profileData) {
            const parsedData = JSON.parse(profileData);
            parsedData.dob = formattedDate;
            localStorage.setItem('userProfileData', JSON.stringify(parsedData));
          }
          setSuccessMessage("Basic details updated successfully.");

        } else {
          setErrorMessage(response.message);
        }
      
    } catch (error) {
      console.error("API Error:", error);
      setErrorMessage("An error occurred while updating the Basic details.");
    } finally {
      setIsLoading(false);
    }
  };
 
  const [isSuccess, setIsSuccess] = useState(false);
  const [show, setShow] = useState(false);
  const [passwordToast, setPasswordToast] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [editUsername, setEditUsername] = useState(false);
  const [currentUsername, setCurrentUsername] = useState('');
  const [editPassword, setEditPassword] = useState(false);
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(false);
  const [asyncRequestResult, setAsyncRequestResult] = useState(null);


  const handleEditClick = (type) => {
    
    if(type === "username") {
      setEditUsername(true);
      document.querySelector('.BasicDetailsBox').style.pointerEvents = 'none';
      document.querySelector('.usernameGroupCol').style.pointerEvents = 'all';
      document.querySelector('.usernameUpdateButton').style.pointerEvents = 'all';
      document.querySelector('.CancelButtonSetup').style.display = 'none';
      document.querySelector('.SubmitButtonSetup').style.display = 'none';

    } else if(type === "updatePwd") {
      setEditPassword(true);
      setPasswordToast(true);
      document.querySelector('.formPasswordId').style.display = 'none';
      document.querySelector('.CancelButtonSetup').style.display = 'none';
      document.querySelector('.SubmitButtonSetup').style.display = 'none';
      document.querySelector('.BasicDetailsBox').style.pointerEvents = 'none';
      document.querySelector('.passwordToastBox').style.pointerEvents = 'all';
      
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  useEffect(() => {
    if (editUsername) {
      async function checkUsernameAvailability() {
        const { username } = user;
        const newErrors = { username: "" };
        setIsModalLoading(true);

        if (!username) {
          newErrors.username = "Username should not be empty.";
          setErrors(newErrors);
          setIsUsernameAvailable(false);
          setIsModalLoading(false);
          return;
        } else if (username.length <= 5) {
          newErrors.username = "Username should be in 5 characters and above.";
          setErrors(newErrors);
          setIsUsernameAvailable(false);
          setIsModalLoading(false);
          return;
        } else if (username === currentUsername) {
          newErrors.username = "New username should not be the same as the old one.";
          setErrors(newErrors);
          setIsUsernameAvailable(false);
          setIsModalLoading(false);
          return;
        }
  
        try {
          setIsModalLoading(true);
          const response = await userServices.checkUsernameAvailability(user.username);
          setAsyncRequestResult(response);
          if (response.statusCode === 200) {
            setIsUsernameAvailable(response.data[0].available ?? false);
            document.querySelector('.usernameUpdateButton').style.display = 'inline-flex';

          } else {
            setIsUsernameAvailable(false);
          }
        } catch (error) {
          console.log("API Error", error);
          setIsUsernameAvailable(false);
        } finally {
          setIsModalLoading(false);
        }
      }
  
      checkUsernameAvailability();
    }
  }, [editUsername, user, currentUsername]);
  

  const updatePassword = async (e) => {
    
    e.preventDefault();
    const { password, newPassword, confirmPassword } = user;
    const newErrors = { password: "", newPassword: "", confirmPassword: ""};
    
    // Regular expression for password validation
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!password) {
      newErrors.password = "Please enter old password";
      setErrors(newErrors);
      return;
    }
    // Check if new password meets the required criteria
    if (!passwordRegex.test(password)) {
      newErrors.password = "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long";
      setErrors(newErrors);
      return;
    }
    if (!newPassword) {
      newErrors.newPassword = "Please enter new password";
      setErrors(newErrors);
      return;
    }
    // Check if new password meets the required criteria
    if (!passwordRegex.test(newPassword)) {
      newErrors.newPassword = "New password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long";
      setErrors(newErrors);
      return;
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = "Please enter confirm password";
      setErrors(newErrors);
      return;
    }
    
    if (newPassword !== confirmPassword) {
      newErrors.confirmPassword = "Passwords doesn't match.";
      setErrors(newErrors);
      return;
    }

    if (newErrors.password || newErrors.newPassword || newErrors.confirmPassword) {
      setErrors(newErrors);
      return;
    }
    
    setErrorMessage("");
    setIsModalLoading(true);
    handleShow(true);
    
    try {

      const response = await userServices.updateUserPassword(password, newPassword);
      
      if (response.statusCode === 200) {
          setIsSuccess(true);
          // Reload the current page after 3 seconds
          setTimeout(() => {
            window.location.reload();
          }, 2000);

        } else {
          handleShow(true);
          setIsSuccess(false);
        }
      
    } catch (error) {
      console.error("API Error:", error);
      setErrorMessage("An error occurred while updating the Basic details.");
    } finally {
      setIsModalLoading(false);
    }
  };

  
  const updateProfileUsername = async (e) => {
    
    e.preventDefault();
    const { username } = user;
    const newErrors = { username: "" };
    
    if (!isUsernameAvailable) {
      newErrors.username = "Please provide proper Username first.";
      setErrors(newErrors);
      return;
    }else if (!username) {
      newErrors.username = "Username should not be empty.";
      setErrors(newErrors);
      setIsUsernameAvailable(false);
      return;
    } else if (username.length <= 5) {
      newErrors.username = "Username should be in 5 characters and above.";
      setErrors(newErrors);
      setIsUsernameAvailable(false);
      return;
    } else if (username === currentUsername) {
      newErrors.username = "New username should not be the same as the old one.";
      setErrors(newErrors);
      setIsUsernameAvailable(false);
      return;
    }

    if (newErrors.username) {
      setErrors(newErrors);
      return;
    }
    
    setErrorMessage("");
    setIsModalLoading(true);
    handleShow(true);
    
    try {

      const response = await userServices.updateUsername(username);
      
      if (response.statusCode === 200) {
          const profileData = localStorage.getItem('userProfileData');
          if (profileData) {
            const parsedData = JSON.parse(profileData);
            parsedData.userId = username;
            localStorage.setItem('userProfileData', JSON.stringify(parsedData));
          }
          setIsSuccess(true);
          
          // Reload the current page after 3 seconds
          setTimeout(() => {
            window.location.reload();
          }, 2000);

        } else {
          handleShow(true);
          setIsSuccess(false);
        }
      
    } catch (error) {
      console.error("API Error:", error);
      setErrorMessage("An error occurred while updating the Basic details.");
    } finally {
      setIsModalLoading(false);
    }
  };


  return (
    
    <Container className='RightPadding'>
    <Row className={`d-flex flex-column align-items-center boxBody BasicDetailsBox ${profileStyles.profileSectionVP}`}>

        <h5 className={`mb-3 mt-2 mainHeaderline`}>Basic Details </h5>
        
        <Row className='d-flex align-items-center justify-content-center'>
        
          {isLoading ? (
            <LoadingOverlay />
          ):(
            
            <Row className='minPadding'>
            
            <Form.Text className="text-danger" align="center">{errorMessage}</Form.Text>
            <Form.Text className="text-success" align="center">{successMessage}</Form.Text>
            
            <Form method="POST" onSubmit={updateBasicDetails}>
            <Row>

                <Col md={6}>
                  <Form.Group controlId="formFullname" className='mb-2'>
                      <Form.Label className={profileStyles.profileFormLabel}>Name*</Form.Label>
                      <Form.Control
                          type="text"
                          placeholder=""
                          name="fullname"
                          className={profileStyles.profileFormControl}
                          value={user.fullname}
                          onChange={(e) => handleInputs(e.target.value, e.target.name)}
                          readOnly
                          disabled
                      />
                      <Form.Text className="text-danger">{errors.fullname}</Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}></Col>

                <Col md={6} className='usernameGroupCol'>
                  <Form.Group controlId="formUsername">
                    <Form.Label className={profileStyles.profileFormLabel}>Username*</Form.Label>
                    <div className="d-flex align-items-center justify-content-end">
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="username"
                        className={profileStyles.profileFormControl}
                        value={user.username}
                        onChange={(e) => handleInputs(e.target.value, e.target.name)}
                        readOnly={!editUsername}
                        disabled={!editUsername}
                      />
                      {!editUsername && (
                        <Image src="/Content/Images/icons/icons-edit.png" alt="edit_username"
                          className={`ml-2 ${profileStyles.editIconImage}`}
                          onClick={() => handleEditClick("username")}
                        />
                      )}
                    </div>
                    <Form.Text className="text-danger">{errors.username}</Form.Text>
                  </Form.Group>
                </Col>
                <Col md={1}>
                  <div className="d-flex align-items-center justify-content-start">
                    {user.username.length >= 5 && asyncRequestResult !== null && (
                      isUsernameAvailable ? (
                        <Image
                          src="/Content/Images/icons/icons-tick.png"
                          alt="valid"
                          className={`ml-2 ${profileStyles.contentShowImage}`}
                        />
                      ) : (
                        <Image
                          src="/Content/Images/icons/icons-multiply.png"
                          alt="notValid"
                          className={`ml-2 ${profileStyles.contentShowImage}`}
                        />
                      )
                    )}
                  </div>
                </Col>
                <Col md={5}></Col>

                <Col md={6}> 
                  <Form.Group controlId="formEmailId">
                      <Form.Label className={profileStyles.profileFormLabel}>Email ID*</Form.Label>
                      <Form.Control
                          type="email"
                          placeholder=""
                          name="email"
                          className={profileStyles.profileFormControl}
                          value={user.email}
                          onChange={(e) => handleInputs(e.target.value, e.target.name)}
                          readOnly
                          disabled
                      />
                      <Form.Text className="text-danger">{errors.email}</Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}></Col>

                <Col md={6} className='passwordToastBox'>
                  <Form.Group controlId="formPasswordId" className='formPasswordId'>
                    <Form.Label className={profileStyles.profileFormLabel}>Password*</Form.Label>
                    
                    <div className="d-flex align-items-center justify-content-end">
                      <Form.Control type={passwordVisible ? 'text' : 'password'}
                        placeholder="" name="password"
                        className={profileStyles.profileFormControl}
                        value={'1234567890'}
                        onChange={(e) => handleInputs(e.target.value, e.target.name)}
                        readOnly={!editPassword}
                        disabled={!editPassword}
                      />
  
                      {!editPassword && (
                        <Image src="/Content/Images/icons/icons-edit.png" alt="edit_password"
                        className={`ml-2 ${profileStyles.editIconImage}`}
                        onClick={() => handleEditClick("updatePwd")}
                        />
                      )}
                    </div>
        
                    <Form.Text className="text-danger">{errors.password}</Form.Text>
                  </Form.Group>

                  <Toast onClose={() => setPasswordToast(false)} show={passwordToast} className='mt-3 mb-3'>
                    
                    <Toast.Body>

                      <Form.Group controlId="formOldPasswordId">
                        <Form.Label className={profileStyles.profileFormLabel}>Old Password*</Form.Label>
                        <div className="d-flex align-items-center justify-content-end">
                          <Form.Control type={passwordVisible ? 'text' : 'password'}
                            placeholder="" name="password"
                            className={profileStyles.profileFormControl}
                            value={user.password}
                            onChange={(e) => handleInputs(e.target.value, e.target.name)}
                          />
                          <div className={profileStyles.eyeIconDiv}
                            onClick={togglePasswordVisibility} >
                            {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                          </div>
                        </div>
                        <Form.Text className="text-danger">{errors.password}</Form.Text>
                      </Form.Group>

                      <Form.Group controlId="formNewPasswordId">
                        <Form.Label className={profileStyles.profileFormLabel}>New Password*</Form.Label>
                        <div className="d-flex align-items-center justify-content-end">
                          <Form.Control type={passwordVisible ? 'text' : 'password'}
                            placeholder="" name="newPassword"
                            className={profileStyles.profileFormControl}
                            value={user.newPassword}
                            onChange={(e) => handleInputs(e.target.value, e.target.name)}
                          />
                          <div className={profileStyles.eyeIconDiv}
                            onClick={togglePasswordVisibility} >
                            {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                          </div>
                        </div>
                        <Form.Text className="text-danger">{errors.newPassword}</Form.Text>
                      </Form.Group>

                      <Form.Group controlId="formConfirmPasswordId">
                        <Form.Label className={profileStyles.profileFormLabel}>Confirm Password*</Form.Label>
                        <div className="d-flex align-items-center justify-content-end">
                          <Form.Control type={passwordVisible ? 'text' : 'password'}
                            placeholder="" name="confirmPassword"
                            className={profileStyles.profileFormControl}
                            value={user.confirmPassword}
                            onChange={(e) => handleInputs(e.target.value, e.target.name)}
                          />
                          <div className={profileStyles.eyeIconDiv}
                            onClick={togglePasswordVisibility} >
                            {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                          </div>
                        </div>
                        <Form.Text className="text-danger">{errors.confirmPassword}</Form.Text>
                      </Form.Group>

                      <Row className='mt-3 d-flex align-items-center justify-content-end'>
                        <Col xs='auto'>
                          <Button className='SubmitButtonmini' onClick={updatePassword} type="submit" >Update</Button>
                        </Col>
                      </Row>
                    </Toast.Body>
                  </Toast>

                </Col>
                <Col md={6}></Col>

                <Col md={6}>
                  <Form.Group controlId="formDob" className='mb-2'>
                    <Form.Label className={profileStyles.profileFormLabel}>DOB*</Form.Label><br/>
                      <div className="calendar-container">
                          <DatePicker
                                  selected={user.dob}
                                  onChange={(date) => handleInputs(date, 'dob')}
                                  className={`form-control ${profileStyles.profileFormControl}`}
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="DD/MM/YYYY"
                                  yearDropdownItemNumber={50}
                                  scrollableYearDropdown
                                  showYearDropdown
                                  dropdownMode="scroll"
                                  isClearable
                                />
                      </div>
                    <Form.Text className="text-danger">{errors.dob}</Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}></Col>

            </Row>
            <Row>
              <br /><br />
            </Row>

            <Row className='d-flex align-items-center justify-content-end'>
              <Col xs='auto'>
                <Button as={Link} className='CancelButtonSetup' to={`/seller/public-profile/${localStorage.getItem('sendbirdUserId')}`}>
                  Cancel
                </Button>   
              </Col>
                &nbsp;&nbsp;
              <Col xs='auto'>
                <Button className='SubmitButtonSetup' type="submit" >Submit</Button>
              </Col>
              <Col xs='auto'>
                <Button className={`usernameUpdateButton ${profileStyles.usernameUpdateButton}`} onClick={updateProfileUsername} type="submit" > Update </Button>
              </Col>
            </Row>

            </Form>
            
            </Row>
        
          )}

        </Row>
        
          <Modal show={show} onHide={handleClose} dialogClassName={profileStyles.dialogClass} className={profileStyles.UpdateProfileModaldiv}>
            <Modal.Header closeButton className={`ModalCloseBtn`}>
            </Modal.Header>
            <Modal.Body>
            {isModalLoading ? (
                <LoadingOverlay />
              ) : isSuccess ? (
                  <div>
                    <Row className={'text-center'}>
                      <Col xs={12}>
                        <h5 className={profileStyles.successProfileModal}>Updated successfully!</h5>
                        <label className={profileStyles.successProfileModalMsg} >Your data successfully updated. </label>
                      </Col>
                    </Row>
                  </div>
              ) : (
                  <div>
                    <Row className={'text-center'}>
                      <Col xs={12}>
                        <h5 className={profileStyles.failureProfileModal} >Update Failed!</h5>
                        <label className={profileStyles.successProfileModalMsg} >Please contact for support team.</label>
                      </Col>
                    </Row>
                  </div>
                )}
            </Modal.Body>
          </Modal>

    </Row>
    </Container>
  );
};

export default BasicDetails;
